import {
  installRegisteredApps,
  MA_APP_IDS,
  registerMembersAreaApps,
} from '@wix/members-area-integration-kit';
import EditorWrapper from './EditorWrapper';
import {
  CHALLENGE_PAGE_ID,
  CHALLENGES_INSTALL_MIGRATION_TYPE,
  CHALLENGES_LIST_PAGE_ID,
  CHALLENGES_LIST_PAGE_MANIFEST_KEY,
  CHALLENGES_LIST_PAGE_TYPE,
  CHALLENGES_PAGE_MANIFEST_KEY,
  CHALLENGES_PAGES,
  GROUPS_APP_ID,
  MEMBERSHIP_APP_ID,
  PAYMENT_PAGE_ID,
  PAYMENT_PAGE_MANIFEST_KEY,
  THANK_YOU_PAGE_ID,
  THANK_YOU_PAGE_MANIFEST_KEY,
} from './app-config';
import { getChallengesManifest } from './manifest';
import AppManifest, { AppManagerEvent } from './types/manifest';
import { ISetupPagesOptions, PageData, TPARef } from './types/common';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { Challenges } from './types/Experiments';

export class ChallengesPlatform {
  private readonly editor: EditorWrapper;
  private readonly translations;
  private flowAPI: EditorScriptFlowAPI;

  constructor(
    editorSDK,
    public readonly appDefId: string,
    flowAPI: EditorScriptFlowAPI,
    public readonly isADI?: boolean,
    public readonly isEditorWizardCreationFlow?: boolean,
  ) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
    this.translations = flowAPI.translations;
    this.flowAPI = flowAPI;
  }

  async isChallengesInstalled() {
    return this.editor.isAppInstalled(this.appDefId);
  }

  async installTPA(appDefId: string) {
    const isTPAInstalled = await this.editor.isAppInstalled(appDefId);

    if (!isTPAInstalled) {
      await this.editor.installTPA(appDefId);
    }
  }

  async handleMigration(payload: {
    migrationType: CHALLENGES_INSTALL_MIGRATION_TYPE;
  }) {
    if (payload?.migrationType === 'install_pricing_plans') {
      await this.installTPA(MEMBERSHIP_APP_ID);
    }

    if (payload?.migrationType === 'install_groups') {
      await this.installTPA(GROUPS_APP_ID);
    }
  }

  async installDependencies() {
    const isClassicEnabled = this.flowAPI.experiments.enabled(
      Challenges.enableAutopilot,
    );
    const isADIEnabled = this.flowAPI.experiments.enabled(
      Challenges.enableAutopilotADI,
    );
    const isEditorXEnabled = this.flowAPI.experiments.enabled(
      Challenges.enableAutopilotEditorX,
    );
    const isMyProgramsEnabled = this.flowAPI.experiments.enabled(
      Challenges.enableMyProgramsMA,
    );
    const isSkipMAInstallationEnabled = this.flowAPI.experiments.enabled(
      Challenges.skipMAInstallation,
    );

    console.log('platform', isMyProgramsEnabled);

    const { isClassicEditor, isADI, isEditorX } = this.flowAPI.environment;
    const isInstallApps =
      (isClassicEditor && !isClassicEnabled) ||
      (isADI && !isADIEnabled) ||
      (isEditorX && !isEditorXEnabled);
    const skipMAInstallation =
      this.isEditorWizardCreationFlow && isSkipMAInstallationEnabled;

    console.log({
      skipMAInstallation,
      isSkipMAInstallationEnabled,
      isEditorWizardCreationFlow: this.isEditorWizardCreationFlow,
    });

    if (isInstallApps) {
      if (!skipMAInstallation) {
        // https://wix.slack.com/archives/CM11JK8NA/p1655885110600659
        await this.installTPA(MEMBERSHIP_APP_ID);
      }
      await this.installTPA(GROUPS_APP_ID);

      await this.editor.openAppPage(CHALLENGES_LIST_PAGE_ID, this.appDefId);
    }

    if (isMyProgramsEnabled) {
      await registerMembersAreaApps([MA_APP_IDS.MY_PROGRAMS]);
    } else {
      await registerMembersAreaApps([MA_APP_IDS.MY_CHALLENGES]);
    }
    await installRegisteredApps();

    console.groupEnd();
  }

  async setupPages(options: ISetupPagesOptions) {
    const { isFirstInstall } = options;
    let challengesPages = await this.challengesPagesOnStage();

    // magic begins. We need to use new layout (Sidebar) for new users by default
    // this code setting value to public data that we cen use later in SP/Controller/Widget as regular setting key.
    if (isFirstInstall && !this.isADI) {
      const challengePageTpaSection = await this.editor.getTpaSection(
        CHALLENGE_PAGE_ID,
        challengesPages,
      );

      if (!challengePageTpaSection) {
        return;
      }

      await this.editor.setPublicData(
        {
          id: challengePageTpaSection.id,
          type: 'DESKTOP',
        },
        {
          key: 'participantLayout',
          value: 'SIDEBAR',
        },
      );

      const challengeListPageTpaSection = await this.editor.getTpaSection(
        CHALLENGES_LIST_PAGE_ID,
        challengesPages,
      );
      if (!challengeListPageTpaSection) {
        return;
      }
      await this.editor.setPublicData(
        {
          id: challengeListPageTpaSection.id,
          type: 'DESKTOP',
        },
        {
          key: 'dpListTitle',
          value: true,
        },
      );
    }
    // end of maaaaagic

    // todo: remove the fix in some time (when all users will be updated)
    const isPaymentPageUpdated = await this.fixPaymentPage();
    if (isPaymentPageUpdated) {
      challengesPages = await this.challengesPagesOnStage();
    }

    const thankYouPage = challengesPages.find(
      (page) => page.tpaPageId === THANK_YOU_PAGE_ID,
    );

    try {
      await this.editor.updatePage(thankYouPage.pageRef, {
        pageUriSEO: 'challenge-thanks',
      });

      // await this.editor.save();
      console.log('[challenges]: Thank you page migrated');
    } catch (err) {
      console.error('[challenges]:error in migrating thank you page', err);
    }

    if (isFirstInstall) {
      await this.editor.setPageInitialTitles(
        challengesPages,
        this.translations,
      );
    }
    // else if (addedPages.length) {
    //   await this.editor.setPageInitialTitles(
    //     challengesPages.filter((page) => addedPages.includes(page.tpaPageId)),
    //     this.translations,
    //   );
    // }

    // await this.editor.setPageInitialTitles(challengesPages, this.translations);
    await this.editor.setNoIndex(challengesPages);

    await this.editor.addPagesToEditorPageMenu(challengesPages);

    await this.linkPageWithManifest({
      manifestKey: PAYMENT_PAGE_MANIFEST_KEY,
      tpaPageId: PAYMENT_PAGE_ID,
      applicationId: this.appDefId,
    });

    await this.linkPageWithManifest({
      manifestKey: CHALLENGES_PAGE_MANIFEST_KEY,
      tpaPageId: CHALLENGE_PAGE_ID,
      applicationId: this.appDefId,
    });

    await this.linkPageWithManifest({
      manifestKey: THANK_YOU_PAGE_MANIFEST_KEY,
      tpaPageId: THANK_YOU_PAGE_ID,
      applicationId: this.appDefId,
    });

    await this.linkPageWithManifest({
      manifestKey: CHALLENGES_LIST_PAGE_MANIFEST_KEY,
      tpaPageId: CHALLENGES_LIST_PAGE_ID,
      applicationId: this.appDefId,
    });
  }

  async fixPaymentPage(): Promise<boolean> {
    const paymentPage = (await this.editor.getAllPages()).find(
      (page) => page.tpaPageId === PAYMENT_PAGE_ID,
    );
    let isPaymentPageCreated = false;

    if (!paymentPage) {
      console.warn(
        'Missed payment page for Online Programs, will try to add it.',
      );

      try {
        await this.editor.addPage(PAYMENT_PAGE_ID, this.appDefId);
        isPaymentPageCreated = true;
        console.warn('Payment page for Online Programs added.');
      } catch (err) {
        console.error("Can't add the payment page for Online Programs", err);
      }
    }

    return isPaymentPageCreated;
  }

  private async installPages(
    isFirstInstall: boolean = false,
  ): Promise<string[]> {
    const allSitePages = await this.editor.getAllPages();
    const addedPages = [];

    await Promise.all(
      CHALLENGES_PAGES.map(async (tpaPageId) => {
        const isPageInstalled = allSitePages.some(
          (page) => page.tpaPageId === tpaPageId,
        );

        if (
          !isPageInstalled &&
          (tpaPageId !== CHALLENGES_LIST_PAGE_ID ||
            (tpaPageId === CHALLENGES_LIST_PAGE_ID && isFirstInstall))
        ) {
          await this.editor.addPage(tpaPageId);
          addedPages.push(tpaPageId);
        }
      }),
    );

    return addedPages;
  }

  private getChallengesTPARefs(allSitePages, applicationId): TPARef[] {
    return allSitePages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          CHALLENGES_PAGES.includes(page.tpaPageId),
      )
      .map(({ id, tpaPageId, title, managingAppDefId }) => {
        return {
          title,
          tpaPageId,
          managingAppDefId,
          pageRef: { id },
        };
      });
  }

  async getManifest(): Promise<AppManifest> {
    return getChallengesManifest(this.translations);
  }

  async linkPageWithManifest(options: {
    applicationId: string;
    manifestKey: string;
    tpaPageId: string;
  }) {
    const { applicationId, manifestKey, tpaPageId } = options;
    try {
      const allSitePages = await this.editor.getAllPages();
      const page: PageData = allSitePages.find((_page) => {
        return (
          _page.managingAppDefId === applicationId &&
          _page.tpaPageId === tpaPageId
        );
      });
      const { id } = page;
      await this.editor.setPageState({ [manifestKey]: [{ id }] });
    } catch (err) {
      console.error("Can't setup page for Online Programs", err);
      console.log(await this.editor.getAllPages());
    }
  }

  async deleteApp(_eventPayload: {
    origin: string;
    publicUrl: string;
    pageRef: { id: string; type: string };
  }) {
    try {
      // const pageOnStage = await this.getPageOnStage(CHALLENGES_LIST_PAGE_ID);

      // await this.editor.deletePage({
      //   pageRef: { id: eventPayload?.pageRef?.id || pageOnStage?.pageRef?.id },
      // });
      await this.editor.deleteApp();
    } catch (e) {
      console.warn('[challenges]', e);
    }
  }

  async challengesPagesOnStage() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);

    const { applicationId } = tpaData;
    return this.getChallengesTPARefs(allSitePages, applicationId);
  }

  async getPageOnStage(pageId: string) {
    const challengesPages = await this.challengesPagesOnStage();

    return challengesPages.find((page) => page.tpaPageId === pageId);
  }

  async getTPASectionId(pageId: string): Promise<string> {
    const challengesPages = await this.challengesPagesOnStage();
    const challengePageTpaSection = await this.editor.getTpaSection(
      pageId,
      challengesPages,
    );

    return challengePageTpaSection?.id || '';
  }

  async openDashboard(actionId: AppManagerEvent) {
    if (actionId === AppManagerEvent.OPEN_DASHBOARD) {
      return this.editor.openDashboard('/challenges');
    }
    if (actionId === AppManagerEvent.OPEN_CREATE_CHALLENGE) {
      return this.editor.openDashboard('/challenges/create');
    }

    if (actionId === AppManagerEvent.OPEN_SP) {
      await this.editor.openAppPage(CHALLENGES_LIST_PAGE_ID, this.appDefId);

      const challengeListPageId = await this.getTPASectionId(
        CHALLENGES_LIST_PAGE_ID,
      );

      try {
        await this.editor.openSP(
          {
            id: challengeListPageId,
            type: 'DESKTOP',
          },
          this.appDefId,
          CHALLENGES_LIST_PAGE_TYPE,
        );
      } catch (e) {
        console.error('Error on open SP from App Manager:', e);
      }
    }

    return;
  }

  handleInstallError() {}
}
